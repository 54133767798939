import React, { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function App() {
  const [weatherData, setWeatherData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  // const [isDarkMode, setIsDarkMode] = useState(true);

  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const response = await axios.get(
          `https://api.weatherapi.com/v1/forecast.json?key=0b8ee5fcdf5747668a8210640241811&q=49.2827,-123.1207&days=9`
        );
        setWeatherData(response.data.forecast.forecastday);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchWeatherData();
  }, []);

  const calculateBikeScore = (temp, wind, rainChance) => {
    let tempScore;
    if (temp >= 15 && temp <= 25) {
      tempScore = 100;
    } else if (temp < 0 || temp > 35) {
      tempScore = 0;
    } else {
      tempScore = 100 - (Math.abs(temp - 20) / 15) * 100;
      tempScore = Math.max(0, tempScore);
    }

    let windScore;
    if (wind <= 10) {
      windScore = 100;
    } else if (wind >= 40) {
      windScore = 0;
    } else {
      windScore = 100 - ((wind - 10) / 30) * 100;
      windScore = Math.max(0, windScore);
    }

    let rainScore = 100 - rainChance;

    const tempWeight = 0.4;
    const windWeight = 0.3;
    const rainWeight = 0.3;

    const score =
      tempScore * tempWeight + windScore * windWeight + rainScore * rainWeight;

    return Math.round(Math.max(0, Math.min(100, score)));
  };

  const calculateHourlyBikeScore = (hour) => {
    let tempScore;
    if (hour.temp_c >= 15 && hour.temp_c <= 25) {
      tempScore = 100;
    } else if (hour.temp_c < 0 || hour.temp_c > 35) {
      tempScore = 0;
    } else {
      tempScore = 100 - (Math.abs(hour.temp_c - 20) / 15) * 100;
      tempScore = Math.max(0, tempScore);
    }

    let windScore;
    if (hour.wind_kph <= 10) {
      windScore = 100;
    } else if (hour.wind_kph >= 40) {
      windScore = 0;
    } else {
      windScore = 100 - ((hour.wind_kph - 10) / 30) * 100;
      windScore = Math.max(0, windScore);
    }

    let rainScore = 100 - hour.chance_of_rain;

    const tempWeight = 0.4;
    const windWeight = 0.3;
    const rainWeight = 0.3;

    const score =
      tempScore * tempWeight + windScore * windWeight + rainScore * rainWeight;

    return Math.round(Math.max(0, Math.min(100, score)));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long" };
    return date.toLocaleDateString(undefined, options);
  };

  const formatHourRange = (timeString) => {
    const date = new Date(timeString);
    const startHour = date.getHours();
    const endHour = (startHour + 2) % 24;

    const formatTime = (hour) => {
      const period = hour >= 12 ? "PM" : "AM";
      const hour12 = hour % 12 || 12;
      return `${hour12}:00 ${period}`;
    };

    return `${formatTime(startHour)} - ${formatTime(endHour)}`;
  };

  const getGradientColor = (score, isHourly = false) => {
    const transparency = isHourly ? 0.1 : 0.2;

    if (score > 75) {
      return {
        gradient: `linear-gradient(135deg, rgba(27, 94, 32, ${transparency}), rgba(46, 125, 50, ${transparency}))`,
        pathColor: "#2e7d32",
      };
    } else if (score > 50) {
      return {
        gradient: `linear-gradient(135deg, rgba(245, 127, 23, ${transparency}), rgba(255, 111, 0, ${transparency}))`,
        pathColor: "#ff6f00",
      };
    } else {
      return {
        gradient: `linear-gradient(135deg, rgba(183, 28, 28, ${transparency}), rgba(198, 40, 40, ${transparency}))`,
        pathColor: "#c62828",
      };
    }
  };

  const handleCardClick = (index) => {
    setSelectedDay(selectedDay === index ? null : index);
  };

  // const toggleTheme = () => {
  //   setIsDarkMode(!isDarkMode);
  // };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching data: {error.message}</p>;

  return (
    <div className="App">
      <div className="floating-ball ball-1"></div>
      <div className="floating-ball ball-2"></div>
      <div className="floating-ball ball-3"></div>
      <div className="floating-ball ball-4"></div>
      <div className="floating-ball ball-5"></div>
      <div className="floating-ball ball-6"></div>
      <div className="floating-ball ball-7"></div>
      <div className="floating-ball ball-8"></div>
      <div className="floating-ball ball-9"></div>
      <header className="App-header">
        <h1>Find the Best Time to Ride</h1>
        <h2>9-Day Weather Forecast</h2>
        <div
          className={`weather-cards ${
            selectedDay !== null ? "expanded-view" : ""
          }`}
        >
          {weatherData.map((day, index) => {
            const temp = day.day.avgtemp_c;
            const wind = day.day.maxwind_kph;
            const rainChance = day.day.daily_chance_of_rain;
            const bikeScore = calculateBikeScore(temp, wind, rainChance);
            const colors = getGradientColor(bikeScore);
            const isSelected = selectedDay === index;

            return (
              <div key={index} className="day-container">
                <div
                  className={`weather-card ${isSelected ? "selected" : ""} ${
                    selectedDay !== null && !isSelected ? "hidden" : ""
                  }`}
                  onClick={() => handleCardClick(index)}
                  style={{
                    background: colors.gradient,
                  }}
                >
                  <div className="card-content">
                    <p className="date">{formatDate(day.date)}</p>
                    <p className="temperature">
                      Temperature: <span>{temp}°C</span>
                    </p>
                    <p className="condition">{day.day.condition.text}</p>
                    <p className="wind">
                      Wind: <span>{wind} kph</span>
                    </p>
                    <p className="rain-chance">
                      Chance of Rain: <span>{rainChance}%</span>
                    </p>
                  </div>
                  <div className="progress-bar">
                    <CircularProgressbar
                      value={bikeScore}
                      text={`${bikeScore}%`}
                      styles={buildStyles({
                        textColor: "#fff",
                        pathColor: colors.pathColor,
                        trailColor: "rgba(255, 255, 255, 0.2)",
                      })}
                    />
                  </div>
                </div>
                {isSelected && (
                  <div className="hourly-cards">
                    {day.hour
                      .filter((_, i) => i % 2 === 0)
                      .map((hour, hourIndex) => {
                        const hourlyScore = calculateHourlyBikeScore(hour);
                        const hourlyColors = getGradientColor(
                          hourlyScore,
                          true
                        );

                        return (
                          <div
                            key={hourIndex}
                            className="weather-card hourly"
                            style={{
                              background: hourlyColors.gradient,
                            }}
                          >
                            <div className="card-content">
                              <p className="date">
                                {formatHourRange(hour.time)}
                              </p>
                              <p className="temperature">
                                Temperature: <span>{hour.temp_c}°C</span>
                              </p>
                              <p className="condition">{hour.condition.text}</p>
                              <p className="wind">
                                Wind: <span>{hour.wind_kph} kph</span>
                              </p>
                              <p className="rain-chance">
                                Chance of Rain:{" "}
                                <span>{hour.chance_of_rain}%</span>
                              </p>
                            </div>
                            <div className="progress-bar">
                              <CircularProgressbar
                                value={hourlyScore}
                                text={`${hourlyScore}%`}
                                styles={buildStyles({
                                  textColor: "#fff",
                                  pathColor: hourlyColors.pathColor,
                                  trailColor: "rgba(255, 255, 255, 0.2)",
                                })}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </header>
      <footer className="App-footer">Created with ❤️ in Vancouver</footer>
    </div>
  );
}

export default App;
